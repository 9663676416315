import { defineStore } from 'pinia';

export interface Option {
  color: string;
  width: number;
}

type Resolve = (value: boolean) => void;
type Reject = (value: boolean) => void;

export const useConfirmDialogStore = defineStore('confirmDialog', {
  state: () => ({
    dialog: false,
    title: '',
    message: '',
    resolve: <Resolve>{},
    reject: <Reject>{},
    option: <Option>{
      width: 450,
      color: 'error',
    },
  }),

  actions: {
    open(title: string, message: string, option?: Option): Promise<boolean> {
      if (option) {
        this.option = option;
      }
      this.title = title;
      this.message = message;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      this.resolve(true);
      this.dialog = false;
    },

    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
})
